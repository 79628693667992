.sideMenu{
    font-size: 2em;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 90vh;
    -webkit-box-shadow: 1px 1px 3px rgba(0,0,0,0.50);
    -moz-box-shadow: 1px 1px 3px rgba(0,0,0,0.50);
    box-shadow: 1px 1px 3px rgba(0,0,0,0.50);
}
.sideItem{
    margin-top: 1em
}
.sideM{
    height: 90vh;
}
.contentM{
    height: 90vh;
    overflow-y: auto;
}
.borde{
    margin: 0 0
}
.pedido i{
    color: blue
}
.produccion i{
    color: gray
}
.bodega i{
    color: brown
}
.prendas i{
    color: rebeccapurple
}
.administracion i {
    color: yellowgreen
}
.contenido{
    padding-left: 0px;
    padding-right: 0px
}
.nav-item{
    font-size: 1.1em !important
}